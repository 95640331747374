import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Project from '../../api/project';
import Benefits from './benefits'
import ServiceSidebar from './sidebar'
import Footer from '../../components/footer/Footer.js'

const ProjectSinglePage = (props) => {
    const { id } = useParams()

    const projectDetails = Project.find(item => item.Id === id)

    return (
        <Fragment>
            <Navbar />
            <PageTitle pageTitle={projectDetails.title} pagesub={'Project'} />
            <section className="wpo-service-single-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="wpo-service-single-wrap">
                                <div className="wpo-service-single-img">
                                    <img src={projectDetails.pImg} alt="" />
                                </div>

                                <div className="wpo-project-details-list">
                                    <div className="row">
                                        <div className="col co-l-lg-4 col-md-4 col-sm-6 col-12">
                                            <div className="wpo-project-details-text">
                                                <span>Cliente / Evento</span>
                                                <h2>{projectDetails.client}</h2>
                                            </div>
                                        </div>
                                        <div className="col co-l-lg-4 col-md-4 col-sm-6 col-12">
                                            <div className="wpo-project-details-text-3">
                                                <span>N.º de Equipamentos</span>
                                                <h2>{projectDetails.numOfEquipments}</h2>
                                            </div>
                                        </div>
                                        <div className="col co-l-lg-4 col-md-4 col-sm-6 col-12">
                                            <div className="wpo-project-details-text">
                                                <span>Data</span>
                                                <h2>{projectDetails.dateOfEvent}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="wpo-service-single-content">
                                    <div className="wpo-service-single-content-des">
                                        <h2>{projectDetails.title}</h2>
                                        <p>{projectDetails.paragraph1}</p>
                                        <p>{projectDetails.paragraph2}</p>
                                        <p>{projectDetails.paragraph3}</p>
                                        <div className="wpo-service-single-sub-img">
                                            <ul>
                                                <li><img src={projectDetails.ssImg1} alt="" /></li>
                                                <li><img src={projectDetails.ssImg1} alt="" /></li>
                                                <li><img src={projectDetails.ssImg2} alt="" /></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ServiceSidebar />
                    </div>
                </div>
            </section>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default ProjectSinglePage;
