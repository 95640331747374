import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },

}));

const Benefits = (props) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        <div className="wpo-benefits-section">
            <div className="row">
                <div className="col-lg-12 col-12">
                    <div className="wpo-benefits-item">
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography className={classes.heading}>Qual a nossa área de atuação?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Trabalhamos de norte a sul de Portugal Continental, no entanto, a nossa grande área de atuação concentra-se nos distritos
                                    de Lisboa, Leiria, Setúbal e Santarém.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                            >
                                <Typography className={classes.heading}>Como posso pedir um orçamento?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Para pedir um orçamento, basta dirigir-se ao nosso formulário <a href="/orcamento">Orçamento</a> e dizer-nos o que pretende.
                                    Enviaremos uma cotação o mais breve possível. <br />Caso prefira, pode sempre contactar-nos por telefone e/ou telemóvel.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                            >
                                <Typography className={classes.heading}>Qual o processo para efetuar um aluguer de equipamento?</Typography>
                
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Para dar início ao processo de aluguer de equipamento, basta enviar-nos um orçamento, para o qual receberá uma cotação.
                                    <br />Após a sua decisão, basta entrar novamente em contact
                                    o connosco para darmos inicio ao contrato e todos os seus trâmites legais.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Benefits;