import React from 'react'
import { Link } from 'react-router-dom'
import Services from '../../api/service';
import livro from '../../images/wc/livro.jpg'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}
const SubmitHandler = (e) => {
    e.preventDefault()
}

const Footer = (props) => {
    return (
        <footer className="wpo-site-footer">
            <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="widget-title">
                                    <h3>Luís Silvestre Unipessoal, Lda.</h3>
                                </div>
                                <p>A WC Portáteis fornece soluções sanitárias portáteis de qualidade para eventos e construções, garantindo a satisfação do cliente por meio de um atendimento personalizado e excelência na limpeza dos equipamentos.</p>
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/profile.php?id=100000410445168" target="_blank">
                                            <i className="ti-facebook"></i>
                                        </a>
                                    </li>
                                </ul>
                                <br /><br />
                                <p>Na eventualidade de necessitar de efetuar uma reclamação, prossiga abaixo para o fazer.</p>
                                <a href="https://www.livroreclamacoes.pt/Pedido/Reclamacao" target="_blank">
                                    <img style={{width: 200}} src={livro} />
                                </a>
                            </div>
                        </div>
                        <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget wpo-service-link-widget">
                                <div className="widget-title">
                                    <h3>Contacte-nos</h3>
                                </div>
                                <div className="contact-ft">
                                    <ul>
                                        <li><i className="fi flaticon-location"></i>Sediados em Lourinhã, Lisboa</li>
                                        <li><i className="fi flaticon-phone-call"></i>Tel. & Fax: <a href="tel: 261469038">261 469 038</a> (chamada para rede fixa nacional)</li>
                                        <li><i className="fi flaticon-phone-call"></i>Tel: <a href="tel: 965623489">965 623 489</a> (chamada para rede móvel nacional)</li>
                                        <li><i className="fi flaticon-send"></i>geral@wcportateis.pt</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Equipamentos </h3>
                                </div>
                                <ul>
                                    {Services.slice(0, 8).map((service, srv) => (
                                        <li key={srv}><Link onClick={ClickHandler} to={`/service-single/${service.Id}`}>{service.sTitle}</Link></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <p className="copyright"> &copy; 2023 Luís Silvestre Unipessoal, Lda - Direitos Reservados</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;