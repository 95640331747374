import React from 'react';
import AllRoute from '../router'
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../sass/style.scss';
import CookieConsent, { Cookies } from "react-cookie-consent";

const App = () => {
   return (
    <div className="App" id='scrool'>
        <CookieConsent
            location="bottom"
            buttonText="Aceito Cookies"
            cookieName="cookieConsent"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
            enableDeclineButton
            flipButtons
            declineButtonText="Não Aceito :("
            onDecline={() => {
                window['ga-disable-G-JY2HCHRVRG'] = true;
                console.log("Google Analytics will be disconnected.");
            }}
        >
            Este site utiliza cookies para permitir uma melhor experiência.{" "}
        </CookieConsent>

        <AllRoute/>
        <ToastContainer/>
    </div>
  );
}

export default App;
