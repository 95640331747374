import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/footer/Footer'
import Scrollbar from '../../components/scrollbar/scrollbar'
import ServiceSection from '../../components/ServiceSection/ServiceSection';


const ServicePage =() => {
    document.title = "Equipamentos :: WC Portáteis - Luís Silvestre Unipessoal, Lda. | Urinóis, Lava Mãos, Contentores WC, Eventos, Obras, Agricultura";
    return(
        <Fragment>
            <Navbar/>
            <PageTitle pageTitle={'Equipamentos'} pagesub={'Equipamentos'}/>
            <ServiceSection/> 
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};

export default ServicePage;
