import React from 'react'
import {Link}  from 'react-router-dom'
import icon1 from '../../images/icon/1.png'
import icon2 from '../../images/icon/2.png'


const HeaderTopbar = () => {
    return(	
        <div className="topbar">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-3 col-md-5 col-sm-12 col-12">
                        <div className="contact-intro">
                            <ul>
                                <li><i><img src={icon1} alt=""/></i>Seg - Sex   ||   8:00 - 20:00</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-9 col-md-7 col-sm-12 col-12">
                        <div className="contact-info">
                            <ul>
                                <li>
                                    <a href="tel:+351965623489"><i><img src={icon2} alt=""/></i> 965 623 489 <span>(chamada para rede móvel nacional) </span></a>
                                    <a href="tel:+351261469038"> 261 469 038 <span>(chamada para rede fixa nacional)</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopbar;