import React from 'react'
import Services from '../../api/service';
import { Link } from 'react-router-dom'

const ServiceSidebar = (props) => {

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <div className="col-lg-4 order-lg-1 order-md-2 order-2">
            <div className="blog-sidebar">
                <div className="widget category-widget">
                    <h3>Equipamentos</h3>
                    <ul>
                        {Services.slice(0, 6).map((service, Sitem) => (
                            <li key={Sitem}><Link onClick={ClickHandler} to={`/service-single/${service.Id}`}>{service.sTitle}</Link></li>
                        ))}
                    </ul>
                </div>
                <div className="wpo-contact-widget widget">
                    <h2>Como podemos <br /> ajudar!</h2>
                    <p>Caso tenha dúvidas sobre este equipamento ou outro qualquer, não hesite em contactar-nos. </p>
                    <Link to="/contact">Contacte-nos</Link>
                </div>
            </div>
        </div>

    )
}

export default ServiceSidebar;