import React from 'react';
import ContactForm from '../ContactFrom/ContactForm'
import useAnalyticsEventTracker from "../../utils/useAnalyticsEventTracker";

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '100%',
  };
  
  const center = {
    lat: 39.23896789550781, 
    lng: -9.309065818786621,
  };

const Contactpage = () => {
    document.title = "Contactos :: WC Portáteis - Luís Silvestre Unipessoal, Lda. | Urinóis, Lava Mãos, Contentores WC, Eventos, Obras, Agricultura";
    const gaEventTracker = useAnalyticsEventTracker('ContactPage');
    return(
        <section className="wpo-contact-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-10 offset-lg-1">
                        <div className="wpo-contact-title">
                            <h2>Alguma Questão?</h2>
                            <p>Caso pretenda ver esclarecida alguma questão, não hesite em contactar-nos pelo formulário abaixo e
                                entraremos em contacto consigo assim que nos seja possível.</p>
                        </div>
                        <div className="office-info">
                            <div className="row">
                                <div className="col col-xl-12 col-lg-12 col-md-12 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-phone-call"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Ligue-nos</h2>
                                            <p><a onClick={()=> gaEventTracker("mobile")} href="tel:965623489">965 623 489</a> (chamada para rede móvel nacional)</p>
                                            <p><a onClick={()=> gaEventTracker("phone")} href="tel:261469038">261 469 038</a> (chamada para rede fixa nacional)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wpo-contact-form-area">
                            <ContactForm/>
                        </div>
                    </div>                
                </div>
            </div> 
            <section className="wpo-contact-map-section">
                <div className="wpo-contact-map">
                    <LoadScript googleMapsApiKey="AIzaSyCtmhzWJw3mK9yAxEy8Y4ekXngLuRGkTH0">
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={14}
                    >
                        <Marker position={center} />
                    </GoogleMap>
                    </LoadScript>
                </div>
            </section>
        </section>
     )
        
}

export default Contactpage;
