import pimg from '../images/projects/coimbra/coimbra.jpg'

const Projects = [
    {
        Id: '1',
        pImg:pimg,
        client: "CM Coimbra",
        numOfEquipments: 63,
        dateOfEvent: "25/04/2023",
        subTitle:'Evento - Coimbra',
        title: '63 WC Portáteis VIP',
        description:'Entrega e Manutenção de 63 WC Portáteis e Urinóis distribuídos por toda a zona do evento, em Coimbra.',
        paragraph1: 'Recentemente, tivemos a honra de participar num importante evento em Coimbra, onde fornecemos e instalamos um total de 63 equipamentos sanitários para garantir a segurança e o conforto dos participantes.',
        paragraph2: 'Foi um trabalho desafiador, mas graças à nossa equipa altamente qualificada e aos nossos anos de experiência no setor, fomos capazes de fornecer soluções sanitárias eficientes e eficazes para o evento.',
        paragraph3: 'Estamos extremamente satisfeitos por ter contribuído para o sucesso deste evento e por ter ajudado a garantir a segurança e o bem-estar dos participantes. Como sempre, continuamos comprometidos em fornecer serviços excecionais e soluções sanitárias de alta qualidade para eventos e projetos em toda a região.',
    }
]
export default Projects;